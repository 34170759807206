import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _set from 'lodash/set';

import { fetchCompositeJSON, fetchJSON } from '@bonnet/next/fetch';

import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import getPreorderYear from '@/utilities/getPreorderYear';

import ParsedQueryModule from '@/modules/ParsedQueryModule';

import getBapTrimId from '@/containers/bap/utilities/getBapTrimId';
import vrsToAtcBodyStyleTransformer from '@/containers/bap/utilities/vrsToAtcBodyStyleTransformer';

import bapActiveModelDuck from './bap/bapActiveModelDuck';
import bapModelInfoDuck from './bap/bapModelInfoDuck';
import srpActiveInteractionDuck from './srp/srpActiveInteractionDuck';

// modules

// Temporary solution until we can figure out shared solution
// These MMT should not show NCCO card or as options on BAP
const excludedNCCO = [
    { makeCode: 'ACURA', modelCode: 'NSX' },
    { makeCode: 'MAS', modelCode: 'MASMC20' },
    { makeCode: 'MB', modelCode: 'MBGLS350D' },
    { makeCode: 'FER' },
    { makeCode: 'MINI', modelCode: 'COUNTRYMAN', trimName: 'Oxford' },
    { makeCode: 'MINI', modelCode: 'COUNTRYMAN', trimName: 'ALL4 Oxford' },
];

// Temporary solution until VRS vehicles endpoint can return one unique model for each body style
// stored as atc model codes
const multiBodyStyleModels = [
    '840CI', 'A5', 'R8', 'BMW428GC', 'BMWM4', 'BMWM8', 'C220', 'C230', 'C250', 'C300', 'C43AMG', 'CIVIC', 'COROL', 'CORV',
    'E300', 'E320', 'E63AMG', 'HYUIONIQ', 'IMPREZ', 'IMPWRX',
    'MAZDA3', 'MAZDASPD3', 'MBE250BLUE', 'MBE400', 'MBE450', 'MUST', 'Z4',
];

const getAtcModel = (selectedModels, modelCodes) => {
    let atcModel;
    if (modelCodes.length === 1) {
        atcModel = modelCodes[0];
    }
    modelCodes.forEach((model) => {
        if (selectedModels.includes(model.code)) {
            atcModel = model;
        }
    });

    return atcModel;
};

const buildTrimOptions = ({
    item,
    selectedModels,
    allOptions,
    drivetrainList,
    engineList,
    exteriorColorList,
    hasMsrpOptionList,
    interiorColorList,
    packageList,
    transmissionList,
}) => ({
    atcMakeCode: item?.atcMake?.code,
    atcMake: item?.atcMake?.name,
    atcModelCode: item?.atcModels ? getAtcModel(selectedModels, item.atcModels)?.code : undefined,
    atcModel: item?.atcModels ? getAtcModel(selectedModels, item.atcModels)?.name : undefined,
    vehicleId: item.vehicleId,
    trimId: item.trimId,
    trimName: item.trimName,
    make: item.manufacturer,
    model: item.model,
    year: item.year,
    bodyStyle: item.bodyStyle || item.vrsBodyStyle,
    availableEngines: engineList,
    availableTransmissions: transmissionList,
    availableDrivetrains: drivetrainList,
    doors: item.doors,
    heatedSeats: item.heatedSeats,
    leatherSeats: item.leatherSeats,
    rearviewCamera: item.rearviewCamera,
    bluetooth: item.bluetooth,
    thirdRow: item.thirdRow,
    alarmSystem: item.alarmSystem,
    laneDeparture: item.laneDeparture,
    nationalMSRP: item.nationalBaseDefaultPrice,
    ncbbPrice: item.ncbbPrice,
    navigation: item.navigation,
    parkAssist: item.parkAssist,
    powerCloseTrunk: item.powerCloseTrunk,
    remoteStart: item.remoteStart,
    sunroof: item.sunroof,
    driveTrain: item.driveTrain,
    engineType: item.engineType,
    imagePath: item.croppedImagePath || item.imagePath,
    exteriorColors: exteriorColorList,
    interiorColors: interiorColorList,
    packages: packageList,
    cabType: item.cabType,
    estimatedElectricRange: item?.estimatedElectricRange,
    mpgCombined: item?.ymmSpecsRange?.mpgCombined,
    mpgECombined: item?.ymmSpecsRange?.mpgECombined,
    epaCity: item?.epaCity,
    epaHwy: item?.epaHwy,
    epaMpgeCity: item?.epaMpgeCity,
    epaMpgeHwy: item?.epaMpgeHwy,
    hasMsrpOptionList,
    allOptions,
});

const buildTrimOptionLists = (vehicleItems) => {
    const packageList = [];
    const exteriorColorList = [];
    const interiorColorList = [];
    const engineList = [];
    const transmissionList = [];
    const drivetrainList = [];
    const allOptions = [];
    const hasMsrpOptionList = [];

    if (vehicleItems) {
        // Any option that has an 'msrp' field present would get the additional cost label in BAP page
        // Get the option list that used in BAP page to check msrp except Packages
        // const bapPreferences = Object.values(bapTitleHelper).filter((pref) => pref.optionsName !== 'Packages').map((pref) => pref.optionsName);

        Object.entries(vehicleItems).forEach(([, opt]) => {
            switch (opt?.categoryGroup) {
                case 'Drivetrain':
                    drivetrainList.push(
                        opt.optionName,
                    );
                    if (opt.msrp) {
                        hasMsrpOptionList.push(
                            opt.optionName,
                        );
                    }
                    break;
                case 'Exterior Colors':
                    exteriorColorList.push(
                        opt.optionName,
                    );
                    if (opt.msrp) {
                        hasMsrpOptionList.push(
                            opt.optionName,
                        );
                    }
                    break;
                case 'Interior Colors':
                    interiorColorList.push(
                        opt.optionName,
                    );
                    if (opt.msrp) {
                        hasMsrpOptionList.push(
                            opt.optionName,
                        );
                    }
                    break;
                case 'Engine':
                    engineList.push(
                        opt.optionName,
                    );
                    if (opt.msrp) {
                        hasMsrpOptionList.push(
                            opt.optionName,
                        );
                    }
                    break;
                case 'Transmission':
                    transmissionList.push(
                        opt.optionName,
                    );
                    if (opt.msrp) {
                        hasMsrpOptionList.push(
                            opt.optionName,
                        );
                    }
                    break;
                default:
                    break;
            }

            if (opt?.categoryGroup?.includes('Packages')) {
                packageList.push(
                    opt.optionName,
                );
                if (opt.msrp) {
                    hasMsrpOptionList.push(
                        opt.optionName,
                    );
                }
            }

            // append all options data to every trim to be able to reference each vehicle option id
            allOptions.push({
                categoryGroup: opt.categoryGroup,
                mandatory: opt.categoryGroup.includes('Mandatory'),
                optionName: opt.optionName,
                optionType: opt.optionType,
                standard: opt.availability === 'Standard',
                footer: opt.footer,
                vehicleOptionId: opt.vehicleOptionId,
                ...(opt.msrp && !opt?.categoryGroup?.includes('Packages') && { msrp: opt.msrp }), // Not include packages because they're optional
            });
        });

    }

    return {
        packageList,
        exteriorColorList,
        interiorColorList,
        engineList,
        transmissionList,
        drivetrainList,
        allOptions,
        hasMsrpOptionList,
    };

};

const isExcludedNCCO = (atcMakeCode, atcModelCode, trimName = '') => {
    const isExcluded = Object.values(excludedNCCO).find((excludedItem) => {
        const hasExcludedMakeModel = atcMakeCode === excludedItem?.makeCode && (excludedItem?.modelCode ? atcModelCode === excludedItem?.modelCode : true);
        return hasExcludedMakeModel && (excludedItem?.trimName ? trimName === excludedItem?.trimName : hasExcludedMakeModel);
    });
    return !!isExcluded;
};

function flattenTrimOptions(_acc, data, src, dest) {
    const items = _get(data, src, []);

    [].concat(items).forEach((value) => {
        if (!_acc[dest].includes(value)) {
            _acc[dest].push(value);
        }
    });

    return _acc;
}

// takes two objects and merges properties with the same key
// if either property value is boolean true, it sets the output value to true
// if values are the same, it keeps the value as a string
// if values are different, it adds them in an array
// if arrays, it appends them together
const mergeTrimOptions = (target, source) => Object.entries(source).reduce((obj, [key, val]) => {
    if (key in obj) {
        if (typeof val === 'boolean') {
            obj[key] = val || obj[key];
        } else if (obj[key] !== val) {
            if (!Array.isArray(obj[key])) {
                obj[key] = [obj[key]];
            }
            [].concat(val).forEach((item) => {
                if (!obj[key].includes(item)) {
                    obj[key].push(item);
                }
            });
        }
    } else {
        obj[key] = val;
    }
    return obj;
}, target);

const fetchOptions = {
    circuitBreaker: {
        id: 'kbbreferencemodels',
        timeout: 8000,
        resetTimeout: 30000,
        fallback: {},
    },
    headers: {
        'X-fwd-svc': 'atc',
    },
};

// get list of models as atc model codes available by make and year
const fetchModelList = async ({ limit = 25, makeCode = '', year = '' }) => {

    const { items = [] } = await fetchJSON('/cars-for-sale/kbbresearch/reference/models', {
        query: {
            atcMakeCode: makeCode,
            limit,
            yearId: year,
        },
        ...fetchOptions,
    });

    // add list of unique model codes
    const models = [];
    items.forEach((item) => {
        const vrsModelCode = item?.atcModels?.[0]?.code;
        const vrsClassSeriesCode = item?.atcClassSeries?.code;
        if (vrsModelCode && !models.includes(vrsModelCode)) {
            models.push({
                vrsModelCode,
                vrsClassSeriesCode,
            });
        }
    });

    return models;
};

const fetchVehicleOptionData = (item) => fetchJSON(`/cars-for-sale/kbbresearch/reference/vehicleoptions/vehicleId/${item.vehicleId}?limit=500`, {
    ...fetchOptions,
});

const fetchVehicleReferenceData = (queryParams) => fetchJSON('/cars-for-sale/kbbresearch/reference/vehicles', {
    query: {
        ...queryParams,
    },
    ...fetchOptions,
});

const fetchVehicleSpecs = (vehicleId) => fetchJSON(`/cars-for-sale/kbbresearch/reference/vehiclespecs/vehicleId/${vehicleId}?limit=500`, {
    ...fetchOptions,
});

// helper method to fetch trim info for each model supplied in the makeModelPairs array
const fetchVehicleInfo = async ({
    enableFetchOptions = true,
    isPreorder = true,
    makeModelPairs = [],
    selectedModel = '',
    year = '',
}) => {

    const newVehicleParams = isPreorder ? { vehicleClass: 'NewCar' } : {};

    const vehicleParams = {
        hasPricing: true,
        ...newVehicleParams,
    };

    /*
    * create makeModelCalls object to perform composite fetch of vehicle reference service
    * if a selected model is passed, then fetch up to 100 trim records for that model, otherwise default to 1
    */
    const makeModelCalls = {};

    for (const mmPair of makeModelPairs) {
        const { makeModel, makeCode, modelCode, year: pairYear } = mmPair;

        if (makeCode && modelCode) {
            const vehicleLimit = (selectedModel && selectedModel.includes(modelCode)
                || (typeof selectedModel === 'string' && (modelCode === selectedModel))
                || multiBodyStyleModels.includes(modelCode)) ? 100 : 5;

            const queryParams = {
                ...vehicleParams,
                limit: vehicleLimit,
                atcMakeCode: makeCode,
                atcModelCode: modelCode,
                yearId: pairYear || year,
            };

            Object.assign(makeModelCalls, {
                [makeModel]: fetchVehicleReferenceData(queryParams),
            });
        }
    }

    // convert vehicles info into an array
    const items = (await fetchCompositeJSON(makeModelCalls)) || {};
    const vehicleItems = [];
    if (items) {
        Object.values(items).forEach((value) => {
            if (value.items) {
                vehicleItems.push(...value.items);
            }
        });
    }

    // create object to perform composite fetch of vehicle options service
    const vehicleOptionsCalls = {};
    if (enableFetchOptions) {
        vehicleItems.forEach((vehicleItem) => {
            Object.assign(vehicleOptionsCalls, {
                [vehicleItem.vehicleId]: fetchVehicleOptionData(vehicleItem),
            });
        });
    }

    const vehicleOptionItems = (await fetchCompositeJSON(vehicleOptionsCalls)) || {};

    const vehicleIdMap = {};

    // for each make-model add all available trim info to each model
    return vehicleItems.length > 0 ? vehicleItems.reduce(async (_acc, item) => {

        const acc = await _acc;
        const selectedModels = [];
        makeModelPairs.forEach((val) => {
            selectedModels.push(val.modelCode);
        });

        if (!item?.trimName) {
            item.trimName = 'Base';
        }

        let vehicleOptions = [];

        if (item.vehicleId) {
            // filter out non-mandatory vehicle options
            const mandatoryOptions = ['Engine', 'Drivetrain', 'Transmission', 'Exterior Colors', 'Interior Colors'];
            const filteredOptions = vehicleOptionItems?.[item.vehicleId]?.items?.filter((optionItem) => (mandatoryOptions.includes(optionItem?.categoryGroup)));

            // create an object that maps a trim id to it's set of vehicle id's broken out by drive type
            const currentVehicleIds = vehicleIdMap[item.trimId];

            if (currentVehicleIds) {
                // need to include drivetype in map?
                const newVehicleIds = {
                    ...currentVehicleIds,
                    [item.driveTrain]: item.vehicleId,
                    [`${item.driveTrain}-options`]: filteredOptions,
                };

                Object.assign(vehicleIdMap, {
                    [item.trimId]: newVehicleIds,
                });
            } else {
                Object.assign(vehicleIdMap, {
                    [item.trimId]: {
                        [item.driveTrain]: item.vehicleId,
                        [`${item.driveTrain}-options`]: filteredOptions,
                    },
                });
            }
            vehicleOptions = vehicleOptionItems?.[item.vehicleId];
        }

        const {
            packageList,
            exteriorColorList,
            interiorColorList,
            engineList,
            transmissionList,
            drivetrainList,
            allOptions,
            hasMsrpOptionList,
        } = buildTrimOptionLists(vehicleOptions?.items);

        const trimOptions = buildTrimOptions({
            item,
            selectedModels,
            allOptions,
            drivetrainList,
            engineList,
            exteriorColorList,
            interiorColorList,
            packageList,
            transmissionList,
            hasMsrpOptionList,
        });

        // Merge trims with the same name
        const makeCodeKey = item?.atcMake?.code;
        const modelCodeKey = trimOptions?.atcModelCode;
        const isValidTrim = item.trimId && makeCodeKey && modelCodeKey
            && !isExcludedNCCO(makeCodeKey, modelCodeKey, item?.trimName) && item.engineType;

        const uniqueTrimId = getBapTrimId(item);

        trimOptions.vehicleIds = vehicleIdMap;

        if (!!isValidTrim && acc?.[makeCodeKey]?.[modelCodeKey]?.[uniqueTrimId]) {
            [acc[makeCodeKey][modelCodeKey][uniqueTrimId], trimOptions].reduce(mergeTrimOptions, {});
        } else if (isValidTrim) {
            _set(acc, `${makeCodeKey}.${modelCodeKey}.${uniqueTrimId}`, trimOptions);
        }

        const { driveTrain, ncbbPrice = 0, vehicleId } = trimOptions;
        if (driveTrain) {
            if (ncbbPrice > 0) {
                _set(acc, `${makeCodeKey}.${modelCodeKey}.${uniqueTrimId}.driveTypeInfo.${driveTrain}.ncbbPrice`, trimOptions.ncbbPrice);
            }
            _set(acc, `${makeCodeKey}.${modelCodeKey}.${uniqueTrimId}.driveTypeInfo.${driveTrain}.vehicleId`, vehicleId);
        }

        return acc;

    }, Promise.resolve({})) : {};
};

// get year from query
const getQueryYear = (queryState) => {
    const { query = {} } = queryState;
    const { endYear, startYear, year } = query;

    return getPreorderYear({ endYear, startYear, year });
};

// TODO rename to vrsVehicleInfoDuck
const kbbVRSDataDuck = objectDuckCreator({
    store: 'kbbVRSData',
    initialState: {
        loading: true,
        modelsLoading: false,
        trimsLoading: false,
        compareListings: {},
        compareTrims: {},
        data: {},
        specs: {},
    },
}).extend({
    creators: () => ({
        /**
         * fetch info for only one model and merge it with exist vrs data
         * @param bodyStyle: - kbb body style name
         * @param makeCode: - atc make code
         * @param modelCode: atc model code
         * @param selectedModel: atc model code - to fetch all trims for
         * @returns {(function(*, *): Promise<void>)|*}
         */
        addModelInfo: ({
            makeCode = '',
            modelCode = '',
            modelsLoading = false,
            selectedModel = '',
        }) => async (dispatch, getState) => {
            dispatch(kbbVRSDataDuck?.creators?.setKeys({
                modelsLoading,
                trimsLoading: true,
            }));
            const { kbbVRSData = {} } = getState();

            if ((makeCode && modelCode)) {
                const makeModelPairs = [{
                    makeCode,
                    modelCode,
                    makeModel: `${makeCode}-${modelCode}`,
                }];

                const vehicleInfo = await fetchVehicleInfo({
                    makeModelPairs,
                    selectedModel,
                    year: getQueryYear(getState()),
                });

                const mergedData = _merge(kbbVRSData?.data, vehicleInfo);

                dispatch(kbbVRSDataDuck?.creators?.setKeys({
                    modelsLoading: false,
                    trimsLoading: false,
                    data: mergedData,
                }));
            }
        },

        /**
         * @param enableFetchOptions: boolean to allow the fetch of vehicle options for each trim of a model
         * @param isPreorder: boolean to only search for new car class
         * @param key: String of parent object to attach data to
         * @param makeModelPairs: Array[Object({ makeCode: make_code, modelCode: model_code }, Object({ makeCode: ... ]
         * @param selectedModel: atc model code - determines how many trims to fetch
         * @param selectedYear: 4 digit number year to overwrite what is in query state

         * @returns {(function(*, *): Promise<void>)|*}
         */
        // TODO rename to loadVehicleInfo
        loadData: ({
            enableFetchOptions = true,
            isPreorder = true,
            key = 'data',
            makeModelPairs = [],
            selectedModel = '',
            selectedYear = '',
        }) => async (dispatch, getState) => {

            const yearId = selectedYear || getQueryYear(getState());

            let isExcludedVehicle = false;
            if (makeModelPairs.length === 1) {
                const makeCode = makeModelPairs?.[0]?.makeCode;
                const modelCode = makeModelPairs?.[0]?.modelCode;
                isExcludedVehicle = isExcludedNCCO(makeCode, modelCode);
            }

            if ((makeModelPairs.length > 0 && !isExcludedVehicle)) {
                dispatch(kbbVRSDataDuck?.creators?.setKeys({ loading: true }));

                const vehicleInfo = await fetchVehicleInfo({
                    enableFetchOptions,
                    isPreorder,
                    makeModelPairs,
                    selectedModel,
                    year: yearId,
                });

                dispatch(kbbVRSDataDuck?.creators?.setKeys({
                    loading: false,
                    [key]: vehicleInfo,
                }));
            }
        },

        /**
         *
         * @param makeCode: String - atcMakeCode
         * @param year: Number - 4 digit year
         *
         * @returns {(function(*, *): Promise<void>)|*}
         */
        loadBodyStyles: ({ makeCode = '', year = '' }) => async (dispatch) => {
            const yearId = year || new Date().getFullYear();
            const { items = [] } = await fetchJSON('/cars-for-sale/kbbresearch/reference/bodystyles', {
                query: {
                    atcMakeCode: makeCode,
                    yearId,
                },
                ...fetchOptions,
            });

            const unorderedBodyStyles = [];
            items.forEach((item) => {
                unorderedBodyStyles.push([vrsToAtcBodyStyleTransformer(item?.name), item?.name]);
            });

            const sortOrder = (order) => (a, b) => order.indexOf(a[0]) - order.indexOf(b[0]);
            const order = ['Sedan', 'SUV', 'Truck', 'Coupe', 'Convertible', 'Wagon', 'Van', 'Hatchback'];

            const bodyStyles = [...unorderedBodyStyles].sort(sortOrder(order));

            await dispatch(kbbVRSDataDuck?.creators?.setKeys({
                bodyStyles,
            }));
        },

        /**
         * fetch all models available for selected make and then fetch one of each trim for those models
         *
         * @param makeCode: String - atcMakeCode
         * @param selectedModel: String - atcModelCode if one is selected - will fetch more trims for this model
         * @param selectedYear: 4 digit number year to overwrite what is in query state
         *
         * @returns {(function(*, *): Promise<void>)|*}
         */
        loadVrsDataWithModels: ({ makeCode = '', selectedModel = '', selectedYear }) => async (dispatch, getState) => {

            const yearId = selectedYear || getQueryYear(getState());

            const models = await fetchModelList({
                limit: 50,
                makeCode,
                year: yearId,
            });

            const makeModelPairs = [];
            models.forEach((model) => {
                makeModelPairs.push({
                    makeCode,
                    modelCode: model.vrsModelCode,
                    makeModel: `${makeCode}-${model.vrsModelCode}`,
                });
            });

            let isExcludedVehicle = false;
            if (makeModelPairs.length === 1) {
                isExcludedVehicle = isExcludedNCCO(makeModelPairs?.[0]?.makeCode, makeModelPairs?.[0]?.modelCode);
            }

            if (makeModelPairs.length === 0) {
                dispatch(kbbVRSDataDuck?.creators?.setKeys({ loading: false }));
            }

            if ((makeModelPairs.length > 0 && !isExcludedVehicle)) {
                dispatch(kbbVRSDataDuck?.creators?.setKeys({ loading: true }));
                const activeSeriesModel = models.find((model) => model.vrsClassSeriesCode === selectedModel);
                const activeSeriesModelCode = activeSeriesModel?.vrsModelCode;

                const activeModel = !selectedModel ? models[0]?.vrsModelCode : (activeSeriesModelCode || selectedModel);

                const vehicleInfo = await fetchVehicleInfo({
                    makeModelPairs,
                    selectedModel: activeModel,
                    year: yearId,
                });

                if (vehicleInfo && makeCode && activeModel) {
                    const defaultTrim = (Object.keys(_get(vehicleInfo, [makeCode, activeModel], {})) || [])[0];
                    dispatch(bapModelInfoDuck.creators.setActiveTrim(defaultTrim));

                }

                dispatch(kbbVRSDataDuck?.creators?.setKeys({
                    loading: false,
                    data: vehicleInfo,
                }));
            }

        },

        loadVehicleSpecs: (isCompareListings = false) => async (dispatch, getState) => {
            let activeTrims = kbbVRSDataDuck.selectors.getActiveModelTrims(getState());
            if (activeTrims.length === 0) {
                activeTrims = kbbVRSDataDuck.selectors.getSelectedMakeModelTrims(getState());
            }
            const activeModels = kbbVRSDataDuck.selectors.getActiveCompareModels(getState());
            let dataSource = activeTrims;
            if (isCompareListings) {
                dataSource = activeModels;
            }

            const specCalls = {};
            dataSource.forEach((trim) => {
                if (trim?.vehicleId) {
                    Object.assign(specCalls, {
                        [trim?.vehicleId]: fetchVehicleSpecs(trim?.vehicleId),
                    });
                }
            });

            const specsData = (await fetchCompositeJSON(specCalls)) || {};

            // reference VehicleSpecs for ids: https://use1-researchable-vrs.awscsrescatnp.kbb.com/swagger/index.html
            const compareSpecIds = [160, 170, 236, 281, 546, 547, 557, 561, 578, 584, 589, 610, 615];

            // filter out unwanted spec ids
            const specs = {};
            Object.entries(specsData).forEach(([key, value]) => {
                const filteredItems = value?.items?.filter((item) => compareSpecIds.includes(item?.specId));
                Object.assign(specs, {
                    [key]: {
                        items: filteredItems,
                    },
                });
            });

            dispatch(kbbVRSDataDuck?.creators?.setKeys({
                loading: false,
                specs,
            }));
        },
    }),
    selectors: () => ({
        getBodyStyles: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ bodyStyles = [] }) => bodyStyles,
        )),

        getLoading: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ loading = false }) => loading,
        )),

        getModelsLoading: (state) => _get(state, 'kbbVRSData.modelsLoading', ''),

        getTrimsLoading: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ trimsLoading = false }) => trimsLoading,
        )),

        getCompareTrims: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ compareTrims = {} }) => compareTrims,
        )),

        getCompareListings: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ compareListings = {} }) => compareListings,
        )),

        getData: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ data = {} }) => data,
        )),

        getSpecs: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ specs = {} }) => specs,
        )),

        getSelectedMake: createSelector(
            ParsedQueryModule.duck.selectors.getDuckState,
            (query) => query.makeCodeList || query.makeCode
        ),

        getSelectedModel: createSelector(
            ParsedQueryModule.duck.selectors.getDuckState,
            bapActiveModelDuck.selectors.getDuckState,
            (query, selectedModel) => selectedModel || query.modelCodeList || query.modelCode,
        ),

        getActiveModelTrims: new DuckSelector((selectors) => createSelector(
            selectors.getCompareTrims,
            srpActiveInteractionDuck.selectors.getDuckState,
            (vrsTrims, srpActiveInteraction) => {
                const selectedModel = _get(srpActiveInteraction, 'activeModel', '');
                const activeTrims = [];

                Object.values(vrsTrims).forEach((model) => {
                    Object.entries(model).forEach(([key, value]) => {
                        if (key === selectedModel || selectedModel.includes(key)) {
                            Object.values(value).forEach((trim) => {
                                activeTrims.push(trim);
                            });
                        }
                    });
                });

                return activeTrims;
            },
        )),

        getActiveCompareModels: new DuckSelector((selectors) => createSelector(
            selectors.getCompareListings,
            (compareListing) => {
                const activeModels = [];
                Object.values(compareListing).forEach((model) => {
                    Object.entries(model).forEach(([, value]) => {
                        Object.values(value).forEach((item) => {
                            activeModels.push(item);
                        });
                    });
                });

                return activeModels;
            },
        )),

        getSelectedTrimInfo: new DuckSelector((selectors) => createSelector(
            selectors.getData,
            selectors.getSelectedMake,
            selectors.getSelectedModel,
            bapModelInfoDuck.selectors.getDuckState,
            (data, makeCode, modelCode, trimId) => _get(data, [makeCode, modelCode, trimId], {})
        )),

        getVehicleOptions: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ vehicleOptions = [] }) => vehicleOptions
        )),

        getSelectedMakeModelTrims: new DuckSelector((selectors) => createSelector(
            selectors.getData,
            selectors.getSelectedMake,
            selectors.getSelectedModel,
            (data, makeCode, modelCode) => Object.values(_get(data, [makeCode, modelCode], {}))
        )),

        getTrimOptions: new DuckSelector((selectors) => createSelector(
            selectors.getSelectedTrimInfo,
            (trim) => {
                if (!trim) {
                    return null;
                }

                // determines the order the option accordions will render on the page
                const optionsData = {
                    availableDriveTrainList: [],
                    availableEngineList: [],
                    availableTransmissionList: [],
                    exteriorColorList: [],
                    interiorColorList: [],
                    packageList: [],
                    hasMsrpOptionList: [],
                };

                optionsData.availableDriveTrainList.push(...Array.from(new Set(trim.availableDrivetrains)));
                optionsData.availableEngineList.push(...Array.from(new Set(trim.availableEngines)));
                optionsData.availableTransmissionList.push(...Array.from(new Set(trim.availableTransmissions)));
                optionsData.interiorColorList.push(...Array.from(new Set(trim.interiorColors)));
                optionsData.exteriorColorList.push(...Array.from(new Set(trim.exteriorColors)));
                optionsData.packageList.push(...Array.from(new Set(trim.packages)));
                optionsData.hasMsrpOptionList.push(...Array.from(new Set(trim.hasMsrpOptionList)));

                return optionsData;
            }
        )),

        // TODO: Determine if this is still needed
        getOptionsData: new DuckSelector((selectors) => createSelector(
            selectors.getSelectedMakeModelTrims,
            (trims) => trims.reduce((acc, trim) => {

                acc = flattenTrimOptions(acc, trim, 'availableDrivetrains', 'availableDriveTrainList');
                acc = flattenTrimOptions(acc, trim, 'interiorColors', 'interiorColorList');
                acc = flattenTrimOptions(acc, trim, 'exteriorColors', 'exteriorColorList');
                acc = flattenTrimOptions(acc, trim, 'availableEngines', 'availableEngineList');
                acc = flattenTrimOptions(acc, trim, 'availableTransmissions', 'availableTransmissionList');
                acc = flattenTrimOptions(acc, trim, 'packages', 'packageList');

                if (trim.trimId) {
                    const trimExists = acc.trimList.some((ele) => ele.trimId === trim.trimId);
                    if (!trimExists) {
                        acc.trimList.push({
                            trimId: trim.trimId,
                            trimName: trim.trimName,
                        });
                    }
                }

                return acc;
            }, {
                trimList: [],
                exteriorColorList: [],
                interiorColorList: [],
                availableEngineList: [],
                availableTransmissionList: [],
                availableDriveTrainList: [],
                packageList: [],
            })
        )),
    }),
});

export default kbbVRSDataDuck;
